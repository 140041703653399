import { render, staticRenderFns } from "./index.vue?vue&type=template&id=30b83726&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=30b83726&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30b83726",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthSignOnConfirm: require('/workspace/components/auth/sign-on/Confirm.vue').default,BSGLogo: require('/workspace/styleguide/components/Logo.vue').default,BSGLink: require('/workspace/styleguide/components/Link.vue').default,CommonVideoStage: require('/workspace/components/common/video-stage/index.vue').default,AuthCode: require('/workspace/components/auth/code/index.vue').default,BSGHeading: require('/workspace/styleguide/components/Heading.vue').default,BSGIcon: require('/workspace/styleguide/components/Icon.vue').default,BSGCardSelectable: require('/workspace/styleguide/components/card/Selectable.vue').default,BSGBanner: require('/workspace/styleguide/components/Banner.vue').default,BSGInput: require('/workspace/styleguide/components/Input.vue').default,BSGButton: require('/workspace/styleguide/components/Button.vue').default})
