












































































































import Vue, { PropType } from 'vue'
import { isValidMailAddress } from '~/services/validation'
import * as CmsApi from '~/api/cms'
import { cmsImageToSources } from '~/api/cms'
import { trackEvent } from '~/services/tracking'

declare const PostAffTracker: any

type AuthSignOnError =
  | 'malformed'
  | 'failed'
  | 'social'
  | 'email_not_verified'
  | 'registration_not_complete'
  | undefined

export default Vue.extend({
  name: 'AuthSignOn',
  props: {
    page: {
      type: String as PropType<string>,
      required: true,
      validator(name: string) {
        return ['sign-up', 'sign-in'].includes(name)
      },
    },
    preEmail: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    socialAuthCode: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    socialAuthError: {
      type: Object as PropType<
        { error: string; reason: string; description: string } | undefined
      >,
      default: undefined,
    },
  },
  data() {
    return {
      socials: ['google', 'facebook', 'apple'],
      mail: '',
      mailInput: this.preEmail ?? '',
      passwordInput: '',
      step: 'mail' as 'mail' | 'code' | 'confirm',
      error: undefined as AuthSignOnError,
      loading: false,
      isMounted: false,
      trainerDetail: {} as CmsApi.CmsTrainerDetailWithQuotesResponse[0],
    }
  },
  computed: {
    type(): 'sign-in' | 'sign-up' {
      return this.$route.name && this.$route.name.startsWith('auth-sign-in')
        ? 'sign-in'
        : 'sign-up'
    },
    signonSwitchTarget(): string | { name: string; query: { email: string } } {
      const target = this.type === 'sign-in' ? 'auth-sign-up' : 'auth-sign-in'
      if (this.preEmail)
        return { name: target, query: { email: this.preEmail } }
      else return target
    },
    errorMessage(): string {
      return this.error
        ? (this.$t(
            `AUTH_SIGN_ON_ERROR_${
              this.error === 'failed' ||
              this.error === 'registration_not_complete'
                ? `${this.type.replace('-', '_').toUpperCase()}_`
                : ''
            }${this.error.toUpperCase()}`
          ) as string)
        : ''
    },
  },
  async mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    if (urlSearchParams.has('step') && urlSearchParams.get('step') === 'code') {
      this.step = 'code'
    }

    const subId: string = urlSearchParams.get('orderID')!
    if (subId) {
      trackEvent(this.$store.state.i18n.locale, 'NewSubscription', {
        subscriptionID: subId,
      })
    }

    this.papTracking()

    await this.preloadTrainer()

    this.isMounted = true
    if (this.socialAuthCode) {
      this.loading = true
      try {
        await this.$vuex.dispatch('auth/socialSignOnCode', {
          code: this.socialAuthCode,
        })
        this.onComplete()
      } catch (e: unknown) {
        this.error = 'social'
      }
      this.loading = false
    } else if (this.socialAuthError) {
      this.error = 'social'
    }
  },
  methods: {
    papTracking() {
      function getParameterByName(name: string, url?: string) {
        if (!url) url = window.location.href
        name = name.replace(/[\[\]]/g, '\\$&')
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
        const results = regex.exec(url)
        if (!results) return null
        if (!results[2]) return ''
        return decodeURIComponent(results[2].replace(/\+/g, ' '))
      }

      try {
        const postAffiliatePro = document.createElement('script')
        postAffiliatePro.setAttribute(
          'src',
          'https://partner.bellicon.com/scripts/trackjs.js'
        )
        postAffiliatePro.setAttribute('id', 'pap_x2s6df8d')
        postAffiliatePro.setAttribute('type', 'text/javascript')

        if (getParameterByName('pap') === '1') {
          PostAffTracker.setAccountId('default1')
          const sale = PostAffTracker.createSale()

          const price = getParameterByName('price')
          if (price) {
            const subtotal: any = price // in cents
            sale.setTotalCost(subtotal / 100)
            sale.setProductID(getParameterByName('productID'))
            sale.setOrderID(getParameterByName('orderID'))
            sale.setData1(getParameterByName('customer'))

            PostAffTracker.register()
          }
        }
      } catch {}
    },
    cmsImageToSources,
    async preloadTrainer() {
      const locale = this.$vuex.getters.language
      const { url, config } = CmsApi.cmsTrainerDetailWithQuotes({ locale })
      const cmsTrainerDetailResponse: CmsApi.CmsTrainerDetailWithQuotesResponse =
        await this.$axios.$get(url, config)
      const index = this.randomInteger(cmsTrainerDetailResponse.length)
      this.trainerDetail = cmsTrainerDetailResponse[index]
    },
    randomInteger(max: number) {
      return Math.floor(Math.random() * max)
    },
    async requestAuth() {
      this.mailInput = this.mailInput.trim()
      this.passwordInput = this.passwordInput.trim()

      if (!isValidMailAddress(this.mailInput)) {
        this.error = 'malformed'
        return
      }
      this.loading = true
      this.mail = this.mailInput
      try {
        const response = await this.$vuex.dispatch('auth/signIn', {
          mail: this.mail,
          password: this.passwordInput,
        })
        if (response.error) {
          this.error = response.error
        } else {
          this.onComplete()
          this.error = undefined
        }
      } catch (_e: unknown) {
        this.error = 'failed'
      }
      this.loading = false
    },
    async socialAuth(provider: string) {
      window.location.href = await this.$vuex.dispatch(
        'auth/socialSignOnStart',
        { provider }
      )
    },
    onComplete() {
      this.step = 'confirm'
      setTimeout(
        () => this.$router.push(this.$routerI18n.localePath({ name: 'video' })),
        3000
      )
    },
  },
})
