














import Vue, { PropType } from 'vue'
import BSGIcon from './Icon.vue'
import { enumValidator } from '~/services/enum-props'
import { getColorStyle } from '~/styleguide/utils'

export const looks: Record<
  string,
  Record<'bg' | 'icon' | 'iconColor', string>
> = {
  info: {
    bg: 'blue-050',
    icon: 'information',
    iconColor: 'blue-500',
  },
  warning: {
    bg: 'yellow-050',
    icon: 'exclamation',
    iconColor: 'orange-400',
  },
  error: {
    bg: 'red-050',
    icon: 'exclamation',
    iconColor: 'red-400',
  },
  success: {
    bg: 'green-050',
    icon: 'checkmark-circle',
    iconColor: 'green-600',
  },
}

type BannerCustom = {
  color: string
  bgColor: string
  icon: string
  iconSize: number
}

export default Vue.extend({
  name: 'BSGBanner',
  components: {
    BSGIcon,
  },
  props: {
    look: {
      type: String as PropType<string>,
      default: 'info',
      validator: enumValidator(Object.keys(looks)),
    },
    custom: {
      type: Object as PropType<BannerCustom>,
      default: undefined,
    },
  },
  data() {
    return { looks }
  },
  computed: {
    color(): string {
      return this.custom ? this.custom.color : this.looks[this.look].iconColor
    },
    bgColor(): string {
      return this.custom ? this.custom.bgColor : this.looks[this.look].bg
    },
    icon(): string {
      return this.custom ? this.custom.icon : this.looks[this.look].icon
    },
    iconSize(): number {
      return this.custom ? this.custom.iconSize : 20
    },
    bgColorStyle(): Partial<CSSStyleDeclaration> {
      return getColorStyle(this.bgColor, 'background-color')
    },
  },
})
